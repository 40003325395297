
import {computed, defineComponent, ref} from "vue";
import store from "@/store";
import {LoadFilterObjects} from "@/core/composite/composite";
import {Actions} from "@/store/enums/StoreEnums";
import Entities from "@/components/base/common/Entities.vue";
import KtDatatable from "@/components/kt-datatable/KTDatatable.vue";
import Avatar from "@/components/base/common/Avatar.vue";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";

export default defineComponent({
  name: "Logins",
  components: {DateTimeFormat, Avatar, KtDatatable, Entities},
  props: {
    groupId: {type: String},
  },
  setup(props) {
    const filterObject = ref({groupId: props.groupId})
    const tableHeader = ref([
      {
        name: "User",
        key: "user",
        sortable: false,
      },
      {
        name: "Time",
        key: "time",
        sortable: false,
      },

      {
        name: "Location",
        key: "location",
        sortable: false,
      },
      /*
            {
              name: "Device",
              key: "device",
              sortable: false,
            },
      */
      /*
            {
              name: "IP",
              key: "ip",
              sortable: false,
            },
      */
      {
        name: "Coordinates",
        key: "coordinates",
        sortable: false,
      },
      /*
            {
              name: "Longitude",
              key: "longitude",
              sortable: false,
            },
      */
      /*
            {
              name: "Longitude",
              key: "longitude",
              sortable: false,
            },
      */
      {
        name: "Access",
        key: "accessAble",
        sortable: false,
      },
      {
        name: "User Agent",
        key: "userAgent",
        sortable: false,
      }
    ]);
    const loginPage = computed(() => store.state.LoginModule.loginPage);
    return {
      ...LoadFilterObjects(Actions.LOAD_LOGIN_AUDITS, filterObject.value, ['user']),
      tableHeader,
      loginPage,
    }
  }
})
